function Education() {
  return (
    <>
      <div>
        <p>
          <span style={{ fontWeight: "bold" }}>
            Computer Software Engineering,
          </span>
          &nbsp;Hack Reactor, 2016-2017
        </p>
        <p>
          <span style={{ fontWeight: "bold" }}>Computer Science,</span>
          &nbsp;Metropolitan State University of Denver
        </p>
      </div>
    </>
  );
}

export default Education;
