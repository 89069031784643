import "./Text.css";

export function Heading({ children }) {
  return <h4>{children}</h4>;
}

export function SubHeading({ children }) {
  return <h4 className="Text-subheading">{children}</h4>;
}

export function Title({ children, style }) {
  return (
    <h4 className="Text-title" style={style}>
      {children}
    </h4>
  );
}

export function BodyLight({ children }) {
  return <p className="Text-body-light">{children}</p>;
}
