import "./Glance.css";

function Glance() {
  return (
    <div>
      <p style={{ marginBottom: 12 }}>
        Strategic engineering leader and people manager with a proven track
        record in guiding cross-functional teams, shaping product direction, and
        driving innovation. Proficient in managing high-impact projects,
        optimizing processes for scalability, and fostering collaboration across
        diverse teams. Skilled in strategic planning, execution, and shaping
        product direction in collaboration with product teams. Passionate about
        coding, marked by a unique blend of technical prowess, exceptional team
        leadership, and a communication style that prioritizes empathy.
        Proficient in end-to-end development encompassing frontend, backend, and
        database technologies across varied tech stacks.
      </p>
      <p>
        <span className="Glance-heading">Languages:</span>
        &nbsp;JavaScript, TypeScript, Python, Java, HTML, & CSS
      </p>
      <p>
        <span className="Glance-heading">Tools/Platforms:</span> Git, GitHub,
        AWS, Babel, Webpack, Cypress, Jira, & NPM
      </p>
      <p>
        <span className="Glance-heading">Frameworks/Libraries:</span> ReactJS,
        AngularJS, Node.js, Django, WebRTC, React Native, & Material UI
      </p>
      <p style={{ marginBottom: 12 }}>
        <span className="Glance-heading">Databases:</span> Firebase, Postgres,
        MySql, & MongoDB
      </p>
      <span className="Glance-heading">Areas of Expertise:</span>
      <ul className="Glance-areas-of-expertise">
        <li>Team Management</li>
        <li>Technical Leadership</li>
        <li>High Growth Start-Up</li>
        <li>UI/UX Expertise</li>
        <li>Project Management & Execution</li>
        <li>Strategic Planning & Direction</li>
        <li>Process Optimization</li>
        <li>Product Roadmap Development</li>
        <li>Software Engineer Mentorship</li>
      </ul>
    </div>
  );
}

export default Glance;
