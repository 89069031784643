import { Heading, SubHeading, Title } from "./components/Text";

function VolunteerWork() {
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Heading>Action in Africa</Heading>
        <SubHeading>Jan 2015 - Present</SubHeading>
      </div>
      <Title>Board Member</Title>
      <p style={{ marginTop: 16 }}>
        Action in Africa strives to Educate, Inspire, and Empower people in
        Uganda by focusing on education and community development. Our goal is
        to provide sustained education, allowing individuals to reach their
        untapped potential and incite economic growth by becoming the next
        leaders, innovators, and entrepreneurs in their country.
      </p>
    </div>
  );
}

export default VolunteerWork;
