import "./App.css";
import Education from "./Education";
import Experience, { experience } from "./Experience";
import Glance from "./Glance";
import Intro from "./Intro";
import OpenSource from "./OpenSource";
import Projects from "./Projects";
import VolunteerWork from "./VolunteerWork";

function SectionHeading({ children }) {
  return (
    <div className="App-section-heading">
      <h3>{children}</h3>
    </div>
  );
}

function Section({ children }) {
  return <section className="App-section">{children}</section>;
}

function SubSection({ children }) {
  return <div className="App-sub-section">{children}</div>;
}

function App() {
  return (
    <div className="App">
      <Section>
        <Intro />
      </Section>
      <Section>
        <div className="App-section-heading">
          <h2>Engineering Manager & Principal Engineer</h2>
        </div>
        <SubSection>
          <Glance />
        </SubSection>
      </Section>
      <Section>
        <SectionHeading>Professional Experience</SectionHeading>
        <SubSection>
          {experience.map((e) => (
            <Experience key={e.company} {...e} />
          ))}
        </SubSection>
      </Section>
      <Section>
        <SectionHeading>Projects & Open-Source Contributions</SectionHeading>
        <SubSection>
          <Projects />
          <OpenSource />
        </SubSection>
      </Section>
      <Section>
        <SectionHeading>Education</SectionHeading>
        <SubSection>
          <Education />
        </SubSection>
      </Section>
      <Section>
        <SectionHeading>Volunteer Work</SectionHeading>
        <SubSection>
          <VolunteerWork />
        </SubSection>
      </Section>
    </div>
  );
}

export default App;
