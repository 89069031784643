import "./Intro.css";
import gitHubLogo from "./github_logo.svg";
import linkedInLogo from "./linkedIn_logo.svg";

function Intro() {
  return (
    <div className="Intro">
      <h1>Derek Young</h1>
      <div className="Intro-contact">
        <div>
          <div className="Intro-contact-link">
            <p>Boulder, CO</p>
          </div>
          <div className="Intro-contact-link">
            <p>(970) 319-7300</p>
          </div>
          <div className="Intro-contact-link">
            <a
              href="mailto: dthomasy@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              dthomasy@gmail.com
            </a>
          </div>
        </div>
        <div>
          <div className="Intro-contact-link">
            <img className="Intro-logo" alt="GitHub logo" src={gitHubLogo} />
            <a
              href="https://github.com/derek-young/"
              target="_blank"
              rel="noreferrer"
            >
              github.com/derek-young
            </a>
          </div>
          <div className="Intro-contact-link">
            <img
              className="Intro-logo"
              alt="LinkedIn logo"
              src={linkedInLogo}
            />
            <a
              href="https://www.linkedin.com/in/derektyoung/"
              target="_blank"
              rel="noreferrer"
            >
              linkedin.com/in/derektyoung
            </a>
          </div>
          <div className="Intro-contact-link">
            <a href="https://derekyoung.info/" target="_blank" rel="noreferrer">
              derekyoung.info
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
