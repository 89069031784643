import { Heading, Title } from "../components/Text";
import "./Projects.css";
import fodmapBuddyLogo from "./fodmap_buddy.png";
import retroFighterLogo from "./retrofighter.png";

function Projects() {
  return (
    <>
      <div>
        <Heading>Projects</Heading>
        <div className="Project">
          <div>
            <img src={retroFighterLogo} alt="Retrofighter logo" />
          </div>
          <div>
            <Title>RetroFighter 2</Title>
            <p>
              Available for iOS&nbsp;
              <a
                className="App-hide-on-print"
                href="https://apps.apple.com/us/app/retrofighter2/id6444370472"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              <a
                className="App-show-on-print"
                href="https://apps.apple.com/us/app/retrofighter2/id6444370472"
                target="_blank"
                rel="noreferrer"
              >
                (at: apps.apple.com/us/app/retrofighter2/id6444370472)
              </a>
              , RetroFighter 2 is an arcade-style, top-down, dog-fighting game
              set in an alien landscape years in the future. A purely personal
              project, it served as an exploration of mobile gaming physics.
            </p>
          </div>
        </div>
        <div className="Project">
          <div>
            <img src={fodmapBuddyLogo} alt="Fodmap Buddy logo" />
          </div>
          <div>
            <Title>FODMAP Buddy</Title>
            <p>
              Built with React Native for simple deployment to iOS and Android,
              FODMAP Buddy allows followers of the FODMAP diet to log the foods
              they eat and track associated symptoms.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Projects;
