import { Heading, Title } from "./components/Text";

function OpenSource() {
  return (
    <>
      <div>
        <Heading>Open-Source Contributions</Heading>
        <div style={{ margin: "2vmin 0" }}>
          <Title>@react-native-google-signin/google-signin</Title>
          <p>React Native wrapper for Google Sign In functionality</p>
        </div>
        <div style={{ margin: "2vmin 0" }}>
          <Title>@recogito/annotorious</Title>
          <p>Image annotation library</p>
        </div>
        <div style={{ margin: "2vmin 0" }}>
          <Title>flowwer-dev/pull-request-stats</Title>
          <p>GitHub action to publish pull request reviewer statistics</p>
        </div>
      </div>
    </>
  );
}

export default OpenSource;
