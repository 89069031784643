import { Heading, SubHeading, Title } from "../components/Text";

import "./Experience.css";

export const experience = [
  {
    company: "Percipient.ai, Santa Clara, CA (Remote)",
    startDate: "May 2018",
    endDate: "Present",
    description: (
      <span>
        Promoted twice in three years&mdash;from Senior Engineer to Staff
        Engineer and then to Principal Engineer&mdash;while concurrently leading
        a six-person engineering team, combining technical proficiency with
        effective team leadership. Collaborate closely with the Chief Product
        Officer and lead Project Manager to shape and contribute to the
        development of the product roadmap. Consistently recognized for
        outstanding performance and adaptability, demonstrating a commitment to
        excellence in both technical and managerial capacities. Proficient in
        managing high-impact projects, maintaining developer velocity, and
        driving process changes for scalability. Tech Stack: React, Django
        Middle-Layer, Postgres + Redis.
      </span>
    ),
    roles: [
      {
        startDate: "Nov 2022",
        endDate: "Present",
        title: "Engineering Manager & Principal Engineer",
        highlights: [
          "Maintained high developer velocity as a manager, completing 22% of all tickets, authoring 31% of all commits, and reviewing 32% of PRs in the last review cycle.",
          <span>
            Architected the user interface for the&nbsp;
            <a
              href="https://percipient.ai/technology/"
              target="_blank"
              rel="noreferrer"
            >
              Mirage Intelligence Analysis Platform
            </a>
            , a cutting-edge computer vision tool empowering analysts to
            anticipate and address potential threats.
          </span>,
          "Developed impactful views and tools, including a live video feed using Dash.js, a map-based geospatial analysis tool, and Kinetic Seek for video navigation.",
          "Led the UI team through a startup's high-growth journey, initiating process changes for scalability and implementing a full testing strategy and TypeScript for elevated code quality.",
          "Contributed to the development and successful implementation of a comprehensive annual performance review process, enhancing organizational efficiency and fostering a culture of excellence.",
        ],
      },
    ],
  },
  {
    company: "Impekable, San Jose, CA (Remote)",
    startDate: "May 2017",
    endDate: "May 2018",
    description:
      "Acted as an outsourced Software Engineer at Impekable, a dynamic digital agency, specializing in custom web and native applications for clients including Google, Accenture, and Percipient.ai, ultimately leading to a full-time role with Percipient.ai. Primary responsibilities included building tailored solutions from customer-defined specifications. Tech Stack: JavaScript, React, Angular, React Native, and Node.js.",
    roles: [
      {
        title: "Software Engineer",
        highlights: [
          "Engineered RESTful APIs to ensure consistent and intuitive customer experiences, enhancing the functionality of web and native applications.",
          "Implemented robust data security measures, incorporating JSON web-token verification and advanced password hashing/salting techniques.",
          "Managed internal project with Google, leading the development of an internal tool for UI/UX auditing, ensuring adherence to Google design standards.",
        ],
      },
    ],
  },
  {
    company: "Appirio, San Francisco, CA (Remote)",
    startDate: "September 2014",
    endDate: "December 2016",
    description:
      "Led digital transformation initiatives for  Appirio, a global leader in technology consulting and cloud-based solutions, specializing in design, construction, and implementation of innovative cloud solutions.",
    roles: [
      {
        title: "Software Integration Developer",
        highlights: [
          "Developed pivotal inbound and outbound interfaces to Workday in Java, ensuring smooth transitions for clients like Bridgewater Financial Services and CNS Wholesale Grocers.",
          "Proficient in designing, building, and deploying custom Workday reports, leveraging JavaScript, CSS, and HTML to enhance reporting capabilities.",
          "Demonstrated effective leadership by guiding a small team through early-stage project planning, ensuring successful project execution.",
          "Spearheaded the development of third-party integrations, streamlining data transfer from Workday into payroll systems for operational efficiency.",
        ],
      },
    ],
  },
];

function Highlights({ highlights }) {
  if (!highlights) return null;

  return (
    <ul className="Experience-highlight-list">
      {highlights.map((h, i) => (
        <li key={i} className="Experience-highlight-list-item">
          {h}
        </li>
      ))}
    </ul>
  );
}

function HeadingContainer({ children }) {
  return <div className="Experience-heading-container">{children}</div>;
}

function Experience({ company, description, roles, startDate, endDate }) {
  const { highlights, title } = roles[0];

  return (
    <div>
      <HeadingContainer>
        <Heading>{company}</Heading>
        <SubHeading>
          {startDate} - {endDate}
        </SubHeading>
      </HeadingContainer>
      <Title style={{ marginBottom: 12 }}>{title}</Title>
      <p>{description}</p>
      <Highlights highlights={highlights} />
    </div>
  );
}

export default Experience;
